import PropTypes from 'prop-types';
import { upperFirst } from 'lodash-es';
import Tooltip from '../../../utils/tooltip';

const sharedPropTypes = {
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    dateLabel: PropTypes.string,
    additionalContent: PropTypes.object,
};

function TooltipContent({ title, date, dateLabel, additionalContent }) {
    return (
        <>
            <h1>
                {upperFirst(gettext('MEASURING_POINT'))}: {title}
            </h1>
            <p>{`${dateLabel}: ${date}`}</p>
            {additionalContent}
        </>
    );
}
TooltipContent.propTypes = sharedPropTypes;

function getBackgroundStyle(color) {
    return {
        backgroundImage: `repeating-linear-gradient(
          135deg,
          transparent,
          transparent 4px,
          ${color} 4px,
          ${color} 8px
        )`,
    };
}

function BaseContactIndicator({
    left,
    width,
    title,
    date,
    dateLabel,
    additionalContent,
    id,
    color,
    bgClass,
}) {
    return (
        <Tooltip
            content={
                <TooltipContent
                    title={title}
                    date={date}
                    dateLabel={dateLabel}
                    additionalContent={additionalContent}
                />
            }
            followCursor={true}
            placement="bottom"
        >
            <div
                style={{
                    left,
                    width,
                    ...color,
                }}
                className={`absolute z-10 h-full ${bgClass}`}
                data-testid={id}
            ></div>
        </Tooltip>
    );
}

BaseContactIndicator.propTypes = {
    ...sharedPropTypes,
    left: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    color: PropTypes.object,
    bgClass: PropTypes.string,
};

export function LastContactIndicator(props) {
    const color = getBackgroundStyle('rgba(0,0,0,0.025)');
    return (
        <BaseContactIndicator
            {...props}
            dateLabel={gettext('LAST_CONTACT')}
            bgClass="bg-grey-graph-overlay"
            color={color}
        />
    );
}

LastContactIndicator.propTypes = BaseContactIndicator.propTypes;

export function LastRecordIndicator(props) {
    const color = getBackgroundStyle('rgba(248,218,40)');
    return (
        <BaseContactIndicator
            {...props}
            additionalContent={
                <p>
                    {gettext('UPLOADING_DATA')}: {props.uploadData || gettext('No data found')}
                </p>
            }
            dateLabel={gettext('LAST_KNOWN_RECORD')}
            bgClass="bg-yellow-graph-overlay opacity-25"
            color={color}
        />
    );
}

LastRecordIndicator.propTypes = BaseContactIndicator.propTypes;
