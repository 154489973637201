import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useObservableEagerState } from 'observable-hooks';
import { BehaviorSubject } from 'rxjs';
import { filter, some } from 'lodash-es';
import { MultiSensorDataSet } from '../../../graph/dataset';
import { getDataType, swarmTypeDataTypes } from '../../../graph/data-types';
import { useNamingConventions } from '../../../utils/naming-conventions';
import { confirm, ConfirmType } from '../../confirm/Confirm';
import Tooltip from '../../../utils/tooltip';

import './DataTabSelector.scss';

class DataTabSelectorState {
    constructor(dataSet) {
        this.dataSet = dataSet;
        this.lastMp = null;
        this.no_data_text = gettext('NO_DATA_IN_GRAPH');
        this.autoSwitch = true;
        this.buttonsState$ = new BehaviorSubject(this.getButtonsState());

        this.dataSet.dataType$.subscribe(this.updateButtonState.bind(this));
        this.dataSet.configs$.subscribe(() => {
            // If our currently active data type is not available in the
            // current view and there are other data types available in this
            // view, we want to switch to the first available data type.
            if (this.autoSwitch && this.switchIfPossible()) {
                return;
            }

            // Re-enable auto switching if it was disabled.
            this.autoSwitch = true;

            this.updateButtonState();
        });
    }

    getDataTypes() {
        return swarmTypeDataTypes[this.dataSet.swarmType];
    }

    getButtonsState() {
        return this.getDataTypes().map((dataType) => {
            const occursInConfigs = some(this.dataSet.configs, getDataType(dataType).configMatcher);

            return {
                type: dataType,
                active: this.dataSet.dataType === dataType,
                disabled: !occursInConfigs,
            };
        });
    }

    updateButtonState() {
        this.buttonsState$.next(this.getButtonsState());
    }

    switch(type) {
        // Temporarily disable auto switching when we
        // manually want to override the active data type.
        this.autoSwitch = false;
        this.dataSet.setDatatype(type);
    }

    /**
     * Checks if the currently active button is disabled and if there is an
     * option to switch to another not disabled button. If an other not disabled
     * button is available, it will activate that button.
     *
     * When a sensor is loaded for the first time, it also shows a message that
     * the active button has automatically switched while scrolling through time.
     *
     * @param {'Object[]'} buttonsState - State of the buttons.
     */
    switchIfPossible() {
        const buttonsState = this.getButtonsState();

        // Reset `firstLoad` when the sensor switched.
        if (this.lastMp !== this.dataSet.sensor) {
            this.lastMp = this.dataSet.sensor;
            this.firstLoad = true;
        }

        const isActiveButtonDisabled = some(buttonsState, {
            active: true,
            disabled: true,
        });

        const notDisabledButtons = filter(buttonsState, {
            active: false,
            disabled: false,
        });

        if (isActiveButtonDisabled && notDisabledButtons.length) {
            // If we not just switched measuring point, throw a message
            // that states there's no data for that type.
            if (!this.firstLoad) {
                confirm({
                    message: `${gettext('Switching to another view due to:')} ${this.no_data_text}`,
                    convertMarkdown: true,
                    type: ConfirmType.INFO,
                });
            }

            // Switch to the first not disabled button.
            this.dataSet.setDatatype(notDisabledButtons[0].type);
            return true;
        }

        this.firstLoad = false;
        return false;
    }
}

const DataTabButton = ({ type, active, disabled, onClick }) => {
    const title = useNamingConventions(getDataType(type).title);

    const dataStateAttribute = useMemo(() => {
        if (active) {
            return 'active';
        }

        if (disabled) {
            return 'disabled';
        }

        return null;
    }, [active, disabled]);

    return (
        <Tooltip content={gettext('NO_DATA_IN_GRAPH')} disabled={!disabled}>
            <button
                key={type}
                data-state={dataStateAttribute}
                className={`data-type-button`}
                onClick={onClick}
                // The ID is needed for Selenium testing.
                id={type}
            >
                {title}
            </button>
        </Tooltip>
    );
};
DataTabButton.propTypes = {
    type: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

const DataTabSelector = ({ dataSet }) => {
    const buttonStateHelper = useMemo(() => new DataTabSelectorState(dataSet), [dataSet]);

    const buttonsState = useObservableEagerState(buttonStateHelper.buttonsState$);

    return (
        <div className="flex">
            {buttonsState.map((buttonState) => (
                <DataTabButton
                    key={buttonState.type}
                    {...buttonState}
                    onClick={() => {
                        buttonStateHelper.switch(buttonState.type);
                    }}
                />
            ))}
        </div>
    );
};

DataTabSelector.propTypes = {
    dataSet: PropTypes.instanceOf(MultiSensorDataSet).isRequired,
};

export default DataTabSelector;
