import { addHours, subMilliseconds, startOfDay, eachMinuteOfInterval } from 'date-fns';
import { utcToZonedTime, formatInTimeZone } from 'date-fns-tz';
import PropTypes from 'prop-types';
import useEventListener from '@use-it/event-listener';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { scaleTime } from 'd3-scale';
import { range } from 'lodash-es';
import { useLocale } from '../../utils/date-fns';
import SkeletonLoader from '../skeletonLoader/SkeletonLoader';
import { format as formatDate } from '../../utils/formatting';
import TimeIndicator from './timeIndicator/TimeIndicator';
import {
    LastContactIndicator,
    LastRecordIndicator,
} from './baseContactIndicator/BaseContactIndicator';
import SelectionWrapper from './selectionWrapper/SelectionWrapper';
import {
    getEventInfo,
    calendarTimezone,
    toCalendarDate,
    getDayPeriod,
    generateBlocksPerDay,
    createTimeSpanObject,
} from './utils';

function LdenCalendar({
    events = [],
    measuringPointName,
    data,
    inViewStartDate,
    dayStartOffset,
    timezone,
    unit,
    loading,
    currentDate,
}) {
    const locale = useLocale();
    const graphGridRef = useRef();
    const graphGridWidthRef = useRef();

    const { date: lastContactDate } = useMemo(
        () => getEventInfo(events, 'EventServerContact', timezone),
        [events, timezone]
    );
    const { date: lastRecordDate, uploads: lastRecordUploads } = useMemo(
        () => getEventInfo(events, 'EventLastRecord', timezone),
        [events, timezone]
    );

    const formatTime = useCallback((date) => formatInTimeZone(date, timezone, 'HH:mm'), [timezone]);

    const startOfWeek = useMemo(() => {
        // Step 1: Convert the inViewStartDate (local browser date) to the
        // specified timezone (e.g., 'Europe/Amsterdam').
        const inViewStartDateZoned = utcToZonedTime(inViewStartDate, timezone);

        // Step 2: Get the start of the day and add our 'start of the day offset' to it.
        const startOfWeekZoned = addHours(startOfDay(inViewStartDateZoned), dayStartOffset);

        // Step 3: Convert the zoned start of the week to UTC.
        return toCalendarDate(startOfWeekZoned);
    }, [inViewStartDate, timezone, dayStartOffset]);

    const days = useMemo(
        () =>
            range(7).map((day) => {
                // We cannot use `addDays` as that does not necessarily move the date 24 hours
                // when moving over DST changes.
                const start = addHours(startOfWeek, day * 24);
                const end = subMilliseconds(addHours(start, 24), 1);

                return {
                    start,
                    end,
                    // Creates the name of the day in the locale provided.
                    name: formatInTimeZone(start, calendarTimezone, 'cccc', { locale }),
                    // Creates a unique key that looks like `2022-365` that can be used in React.
                    key: formatInTimeZone(start, calendarTimezone, 'yyyy_D', {
                        useAdditionalDayOfYearTokens: true,
                    }),
                };
            }),
        [startOfWeek, locale]
    );

    // Create time columns by dividing the first day in 'days' into 60-minute intervals
    // and formatting each interval's hour in the calendar timezone.
    const timeColumns = useMemo(
        () =>
            eachMinuteOfInterval(
                {
                    start: days[0].start,
                    end: days[0].end,
                },
                { step: 60 }
            ).map((d) => formatInTimeZone(d, calendarTimezone, 'HHmm')),
        [days]
    );
    const [scalesPerDay, setScalesPerDay] = useState(null);

    const calculateScalesPerDay = useCallback(() => {
        const { width } = graphGridRef.current.getBoundingClientRect();

        // Store the used width to prevent unnecessary recalculation triggered
        // by the resize event while the width stays the same.
        graphGridWidthRef.current = width;

        setScalesPerDay(
            new Map(
                days.map((day) => [day, scaleTime().domain([day.start, day.end]).range([0, width])])
            )
        );
    }, [days, graphGridRef]);

    const allBlocksPerDay = useMemo(() => {
        if (!scalesPerDay) return new Map();
        const scaleDays = Array.from(scalesPerDay.keys());

        const currentTimeData = createTimeSpanObject(currentDate, currentDate);

        const blocksToRender = [
            {
                data,
                Element: SelectionWrapper,
                elementProps: {
                    formatTime,
                    unit,
                },
            },
            {
                data: currentTimeData,
                Element: TimeIndicator,
            },
        ];

        if (lastContactDate) {
            blocksToRender.push({
                data: createTimeSpanObject(lastContactDate, scaleDays[6].end),
                Element: LastContactIndicator,
                elementProps: {
                    title: measuringPointName,
                    date: formatDate(lastContactDate, { timezone }),
                },
            });
        }

        if (lastContactDate && lastRecordDate) {
            blocksToRender.push({
                data: createTimeSpanObject(lastRecordDate, lastContactDate),
                Element: LastRecordIndicator,
                elementProps: {
                    title: measuringPointName,
                    uploadData: lastRecordUploads,
                    date: formatDate(lastRecordDate, { timezone }),
                },
            });
        }

        return generateBlocksPerDay({
            scalesPerDay,
            timezone,
            blocksToRender,
        });
    }, [
        scalesPerDay,
        data,
        timezone,
        formatTime,
        unit,
        currentDate,
        lastContactDate,
        measuringPointName,
        lastRecordDate,
        lastRecordUploads,
    ]);

    useEffect(() => {
        calculateScalesPerDay();
    }, [calculateScalesPerDay]);

    useEventListener('resize', () => {
        const { width } = graphGridRef.current.getBoundingClientRect();

        if (width !== graphGridWidthRef.current) {
            calculateScalesPerDay();
        }
    });

    const timePeriods = useMemo(
        () => [gettext('DAY'), getDayPeriod(locale, 'evening'), getDayPeriod(locale, 'night')],
        [locale]
    );

    return (
        <div className="flex py-8 text-[9px]">
            <div className="mt-2.5 w-24 font-bold">
                {days.map(({ name, start }, index) => (
                    <div key={index} className="h-20 pb-10 pt-8 text-xs uppercase">
                        {name}
                        <div className="text-2xs text-menu-color">
                            {formatDate(start, { timezone: calendarTimezone, hideTime: true })}
                        </div>
                    </div>
                ))}
            </div>
            <div className="w-full">
                <div className="relative -top-8 flex justify-around">
                    {timePeriods.map((time, index) => (
                        <div key={index} className="grow text-center uppercase">
                            {time}
                        </div>
                    ))}
                </div>
                <div className="relative -left-2.5 -mt-7 flex">
                    {timeColumns.map((time) => (
                        <div key={time} className="grow">
                            {time}
                        </div>
                    ))}
                </div>
                <div ref={graphGridRef} className="mt-1">
                    <div className="relative flex">
                        {Array.from({ length: 24 }).map((_, index) => (
                            <div
                                key={index}
                                className="h-2 grow border-x-[0.5px] border-light-gray px-3"
                            ></div>
                        ))}
                    </div>
                    {days.map((day) => (
                        <div key={day.key} className="relative flex">
                            {loading ? (
                                <SkeletonLoader
                                    wrapperClassName={'h-20 flex-1 p-1'}
                                    className={'h-full p-1'}
                                />
                            ) : (
                                <>
                                    {timeColumns.map((time) => (
                                        <div
                                            key={time}
                                            id={`${day.key}_${time}`}
                                            className="h-20 grow border-[0.5px] border-light-gray bg-gray-200 px-3"
                                        ></div>
                                    ))}
                                    {allBlocksPerDay.get(day)?.map((block, index) => {
                                        const { Element, props, elementProps } = block;
                                        const id = `${Element.displayName}_${day.name}_${index}`;
                                        return (
                                            <Element
                                                key={id}
                                                id={id}
                                                block={props}
                                                {...elementProps}
                                                {...props}
                                            />
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

LdenCalendar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    inViewStartDate: PropTypes.object.isRequired,
    dayStartOffset: PropTypes.number.isRequired,
    timezone: PropTypes.string.isRequired,
    unit: PropTypes.string,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    loading: PropTypes.bool,
    events: PropTypes.array,
    measuringPointName: PropTypes.string,
};

export default LdenCalendar;
